import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { StaticQuery, graphql } from "gatsby"
import StoryCard from "../blog/storyCard"
import Buttons from "../common/button"

function StoriesList() {
  const [itemsToBeShown, setItemsToBeShown] = useState(12)

  const [blogListCount, setBlogListCount] = useState(0)

  var currentItem = 0

  var delayCount = 0;

  return (
    <>
      <StaticQuery
        query={graphql`
          query storiesList {
            SuperOps {
              posts(skip: 4, orderBy: date_DESC) {
                title
                excerpt
                author {
                  name
                  slug
                  team
                }
                coverImage {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 516 } }
                    }
                  )
                }
                tags
                readTime
                slug
                isBlog
              }
            }
          }
        `}
        render={data => (
          <>
            {setBlogListCount(data.SuperOps.posts.length)}
            {data.SuperOps.posts.map((post, i) => {
              if (delayCount > 5) {
                delayCount = 0
              }
              const {
                title,
                excerpt,
                author,
                coverImage,
                tags,
                readTime,
                slug,
                isBlog,
              } = post

              return (
                <>
                  <div
                    className="grid-lg-3 grid-md-2 grid-sm-1"
                    style={{
                      display: currentItem >= itemsToBeShown && "none",
                    }}
                  >
                    <Fade delay={(delayCount + 1) * 130} duration="500">
                      <span className="dspnone">{currentItem++}{delayCount++}</span>
                      <StoryCard
                        title={title}
                        excerpt={excerpt}
                        author={author}
                        coverImage={coverImage}
                        tags={tags}
                        readTime={readTime}
                        slug={slug}
                        contentEllipseLine="3"
                        // headingEllipseLine="2"
                        // headingEllipseLineMob="3"
                        cardSize="small"
                        contentMinHeight="368px"
                        isBlog={isBlog}
                        style={{ marginBottom: "48px" }}
                      />
                    </Fade>
                  </div>
                </>
              )
            })}
          </>
        )}
      />
      {blogListCount <= 12 || itemsToBeShown >= blogListCount ? null : (
        <div className="grid-view-more">
          <Buttons theme="primary lg" text="VIEW MORE STORIES" onClick={() => setItemsToBeShown(itemsToBeShown + 6)} />   
        </div>
      )}
    </>
  )
}

export default StoriesList
