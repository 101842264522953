import React from "react"
import { StaticQuery, graphql } from "gatsby"
import StoryCard from "../blog/storyCard"

const landingLatestStory = props => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query LandingLatestStory {
            SuperOps {
              posts(
                first: 1
                orderBy: date_DESC
                where: { isBlog: Yes }
              ) {
                title
                excerpt
                author {
                  name
                  slug
                  team
                }
                coverImage {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 750 } }
                    }
                  )
                }
                isBlog
                tags
                readTime
                slug
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.posts.map(post => {
              const {
                title,
                excerpt,
                author,
                coverImage,
                tags,
                isBlog,
                readTime,
                slug,
              } = post
              return (
                <StoryCard
                  title={title}
                  excerpt={excerpt}
                  author={author}
                  coverImage={coverImage}
                  tags={tags}
                  readTime={readTime}
                  slug={slug}
                  isBlog={isBlog}
                  contentEllipseLine="3"
                  cardSize="large"
                />
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default landingLatestStory
