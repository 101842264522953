import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { StaticQuery, graphql } from "gatsby"
import StoryCard from "../blog/storyCard"
import StoriesList from "../blog/storiesList"
import { Dropdown } from "react-bootstrap"
import EsArrowDown from "../../assets/images/resources/es-arrow-down.png"
import Buttons from "../common/button"

function LandingOlderStories() {
  const [activeName, setActiveName] = useState("All")

  const [itemsToBeShown, setItemsToBeShown] = useState(6)

  const [blogListCount, setBlogListCount] = useState(0)

  var currentItem = 0

  var delayCount = 0

  const handleTabChange = name => {
    setActiveName(name)
    currentItem = 0
    setItemsToBeShown(12)
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query landingOlderStories {
            SuperOps {
              tags(where: { isBlog: Yes }) {
                name
                isFeatured
                slug
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                posts(orderBy: date_DESC) {
                  title
                  excerpt
                  author {
                    name
                    slug
                    team
                  }
                  coverImage {
                    url(
                      transformation: {
                        document: { output: { format: webp } }
                        image: { resize: { width: 516 } }
                      }
                    )
                  }
                  tags
                  readTime
                  slug
                  isBlog
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="drp-down-wrapper">
              <Dropdown id="drp-main">
                <Dropdown.Toggle variant="success" id="drp-btn">
                  {activeName}
                  <img src={EsArrowDown} height="1" width="1" alt="img" />
                </Dropdown.Toggle>

                <Dropdown.Menu id="drp-menu">
                  <Dropdown.Item>
                    <div
                      className="ch-title"
                      onClick={() => handleTabChange("All")}
                      onKeyDown={() => handleTabChange("All")}
                      role="button"
                      tabIndex={0}
                    >
                      <p>{"All"}</p>
                    </div>
                  </Dropdown.Item>
                  {data.SuperOps.tags.map((tag, i) => {
                    return (
                      <Dropdown.Item>
                        <div
                          className="ch-title"
                          onClick={() => handleTabChange(tag.name)}
                          onKeyDown={() => handleTabChange(tag.name)}
                          tabIndex={0}
                        >
                          <p>{tag.name}</p>
                        </div>
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <nav className="title-list nav nav-tabs">
              <h5
                className={`alt ${"All" === activeName && "active"}`}
                onClick={() => handleTabChange("All")}
                onKeyDown={() => handleTabChange("All")}
                tabIndex={0}
              >
                All
              </h5>
              {data.SuperOps.tags.map(tag => {
                const { name } = tag
                return (
                  <h5
                    className={`alt ${name === activeName && "active"}`}
                    onClick={() => handleTabChange(name)}
                    onKeyDown={() => handleTabChange(name)}
                    role="button"
                    tabIndex={0}
                  >
                    {name}
                  </h5>
                )
              })}
            </nav>

            <div className="grid-container grid-view">
              {"All" === activeName && (
                <>
                  <StoriesList />
                </>
              )}

              {data.SuperOps.tags.map(t => {
                const { name, posts } = t
                return (
                  <>
                    {name === activeName && (
                      <>
                        {setBlogListCount(posts.length)}

                        {posts.map((post, i) => {
                          if (delayCount > 5) {
                            delayCount = 0
                          }
                          const {
                            title,
                            excerpt,
                            author,
                            coverImage,
                            tags,
                            readTime,
                            slug,
                            isBlog,
                          } = post
                          return (
                            <div
                              className="grid-lg-3 grid-md-2 grid-sm-1"
                              style={{
                                display:
                                  currentItem >= itemsToBeShown && "none",
                              }}
                            >
                              <Fade
                                delay={(delayCount + 1) * 130}
                                duration="500"
                              >
                                <span className="dspnone">
                                  {currentItem++}
                                  {delayCount++}
                                </span>
                                <StoryCard
                                  title={title}
                                  excerpt={excerpt}
                                  author={author}
                                  coverImage={coverImage}
                                  tags={tags}
                                  readTime={readTime}
                                  slug={slug}
                                  contentEllipseLine="3"
                                  // headingEllipseLine="2"
                                  cardSize="small"
                                  contentMinHeight="368px"
                                  isBlog={isBlog}
                                  style={{ marginBottom: "48px" }}
                                />
                              </Fade>
                            </div>
                          )
                        })}

                        {blogListCount <= 12 ||
                        itemsToBeShown >= blogListCount ? null : (
                          <div className="grid-view-more">
                            <Buttons
                              theme="primary lg"
                              text="VIEW MORE STORIES"
                              onClick={() =>
                                setItemsToBeShown(itemsToBeShown + 6)
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )
              })}
            </div>
          </>
        )}
      />
    </>
  )
}

export default LandingOlderStories
