import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import StoryCard from "../blog/storyCard"

const landingRecentStories = props => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query landingRecentStories {
            SuperOps {
              posts(
                first: 3
                skip: 1
                orderBy: date_DESC
                where: { isBlog: Yes }
              ) {
                title
                excerpt
                author {
                  name
                  slug
                  team
                }
                coverImage {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 750 } }
                    }
                  )
                }
                isBlog
                tags
                readTime
                slug
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="recent-stories-desk">
              <Row>
              <Col lg={6}>
                {data.SuperOps.posts.map((post, postIndexLeft) => {
                  const { title, author, coverImage, readTime,isBlog, slug } = post
                  return (
                    <>
                      {postIndexLeft !== 2 && (
                        <StoryCard
                          headingFontSize={"20px"}
                          title={title}
                          author={author}
                          coverImage={coverImage}
                          readTime={readTime}
                          slug={slug}
                          contentEllipseLine="3"
                          headingEllipseLine="2"
                          cardSize="small"
                          contentMinHeight="166px"
                          coverImageHeight="114px"
                          isBlog={isBlog}
                          style={{ marginBottom: "20px" }}
                        />
                      )}
                    </>
                  )
                })}
              </Col>

              <Col lg={6} className="big-card">
                {data.SuperOps.posts.map((post, postIndexRight) => {
                  const { title, author, coverImage, excerpt, tags, readTime,isBlog, slug } = post
                  return (
                    <>
                      {postIndexRight === 2 && (
                        <StoryCard
                          title={title}
                          excerpt={excerpt}
                          author={author}
                          coverImage={coverImage}
                          tags={tags}
                          readTime={readTime}
                          slug={slug}
                          contentEllipseLine="3"
                          headingEllipseLine="2"
                          cardSize="small"
                          isBlog={isBlog}
                        />
                      )}
                    </>
                  )
                })}
              </Col>
            </Row>
          </div>
          <div className="recent-stories-mob">
            <Row>
              <Col lg={6}>
                    <>
                      <StoryCard
                        title={data.SuperOps.posts[0].title}
                        author={data.SuperOps.posts[0].author}
                        coverImage={data.SuperOps.posts[0].coverImage}
                        readTime={data.SuperOps.posts[0].readTime}
                        slug={data.SuperOps.posts[0].slug}
                        contentEllipseLine="3"
                        headingEllipseLine="2"
                        cardSize="small"
                        contentMinHeight="150px"
                        isBlog={data.SuperOps.posts[0].isBlog}
                        coverImageHeight="114px"
                        style={{ marginBottom: "20px" }}
                      />
                      <StoryCard
                        title={data.SuperOps.posts[2].title}
                        excerpt={data.SuperOps.posts[2].excerpt}
                        author={data.SuperOps.posts[2].author}
                        coverImage={data.SuperOps.posts[2].coverImage}
                        tags={data.SuperOps.posts[2].tags}
                        readTime={data.SuperOps.posts[2].readTime}
                        slug={data.SuperOps.posts[2].slug}
                        contentEllipseLine="3"
                        headingEllipseLine="2"
                        cardSize="small"
                        isBlog={data.SuperOps.posts[0].isBlog}
                        contentMinHeight="370px"
                        style={{ marginBottom: "20px" }}
                      />
                      <StoryCard
                        title={data.SuperOps.posts[1].title}
                        author={data.SuperOps.posts[1].author}
                        coverImage={data.SuperOps.posts[1].coverImage}
                        readTime={data.SuperOps.posts[1].readTime}
                        slug={data.SuperOps.posts[1].slug}
                        contentEllipseLine="3"
                        headingEllipseLine="2"
                        cardSize="small"
                        isBlog={data.SuperOps.posts[0].isBlog}
                        contentMinHeight="150px"
                        coverImageHeight="114px"
                      />
                    </>
              </Col>
            </Row>
          </div>
        </>
        )}
      />
    </>
  )
}

export default landingRecentStories
