import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import AuthorCard from "../blog/authorCard"
import ContributeCardImage from "../../assets/images/write-for-illustration.svg"
import CarouselComponent from "./../common/carousel-component"
import Buttons from "../common/button"

const landingFeaturedAuthors = () => {

  return (
    <>
      <StaticQuery
        query={graphql`
          query landingFeaturedAuthors {
            SuperOps {
              authors(
                first: 3
                orderBy: featureOrder_ASC
                where: { isFeatured: Yes }
              ) {
                name
                title
                picture {
                  url
                }
                slug
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="featured-author-desk d-dsk">
              {data.SuperOps.authors.map(author => {
                const { name, title, picture, slug } = author
                return (
                  <AuthorCard
                    name={name}
                    title={title}
                    picture={picture}
                    slug={slug}
                    style={{ marginBottom: "16px" }}
                  />
                )
              })}
            </div>
            <div className="featured-author-mob d-mob">
              <CarouselComponent items = {1} status statusText="" swipeable>
                {data.SuperOps.authors.map(author => {
                  const { name, title, picture, slug } = author
                  return (
                    <AuthorCard
                      name={name}
                      title={title}
                      picture={picture}
                      slug={slug}
                      style={{ marginBottom: "16px" }}
                    />
                  )
                })}
              </CarouselComponent>
            </div>
            <div className="author-contribute">
              <Row>
                <Col lg={5} sm={3} xs={5}>
                  <img src={ContributeCardImage} alt="Contribute to Bugle" />
                </Col>
                <Col lg={7} sm={9} xs={7}>
                  <h6>Write for the Bugle</h6>
                  <span className="p12">
                    <p>
                      If you have an idea that will move our industry forward,
                      we'd like to hear it.
                    </p>
                  </span>
                  <span className="author-contribute-button-mob">
                    <Buttons
                      theme="primary"
                      arrow
                      link="/blog/authors#WriteForUs"
                      text="I'M INTERESTED"
                    />
                  </span>
                </Col>
              </Row>
              <span className="author-contribute-button-desk">
                <Buttons
                  theme="primary mt-4 w-100"
                  arrow
                  link="/blog/authors#WriteForUs"
                  text="I'M INTERESTED"
                />
              </span>
            </div>
          </>
        )}
      />
    </>
  )
}

export default landingFeaturedAuthors
